import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import style from './Comparison.module.scss';

const Comparison = ({ primary, items }) => {
    const { heading } = primary;

    return (
        <div className={style.Comparison}>
            <div className={style.container}>
                <div className={style.heading}>
                    <RichText render={heading.richText} />
                </div>
                <div className={style.plans}>
                    <div className={style.blank}></div>
                    {items.map((item, index) => (
                        <div
                            className={
                                index === 0
                                    ? style.planOne
                                    : index === 1
                                        ? style.planTwo
                                        : index === 2
                                            ? style.planThree
                                            : style.planFour
                            }
                            key={index}
                        >
                            <div className={style.planType}>
                                <RichText render={item.plantype.richText} />
                            </div>
                            <div className={style.innerBox}>
                                <div className={style.planAmount}>
                                    <RichText render={item.planamount.richText} />
                                </div>
                                <div className={style.planValidity}>
                                    <RichText render={item.planvalidity.richText} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

Comparison.propTypes = {
    primary: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired,
};

export default Comparison;
