const parameters = {
  slidesPerView: 'auto',
  spaceBetween: 16,
  breakpoints: {
    992: {
      spaceBetween: 34,
    },
  },
  centeredSlides: true,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  loopSlides: 50,
  loopAdditionalSlides: 50,
  direction: 'horizontal',
  loop: true,
};

export { parameters };
