import React from 'react';
import PropTypes from 'prop-types';
import BenefitTable from '../BenefitTable';
import styles from './Legislationsupport.module.scss';

const Legislationsupport = ({ primary, items }) => {
  return (
    <>
      <div className={styles.Legislationsupport}>
        <BenefitTable
          primary_feature_heading={primary.feature_heading?.richText}
          primary_feature_sub_heading={primary.feature_sub_heading?.richText}
          primary_feature_title={primary.feature_title?.richText}
          primary_for_advance_plan={primary.for_advance_plan?.richText}
          primary_for_business_plan={primary.for_business_plan?.richText}
          primary_for_free_plan={primary.for_free_plan?.richText}
          primary_for_small_plan={primary.for_small_plan?.richText}
          primary_laws_for_plan={primary.laws_for_plan?.richText}
          items={items}
        />
      </div>
    </>
  );
};

Legislationsupport.propTypes = {
  primary: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
};

export default Legislationsupport;
