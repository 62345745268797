import React from 'react';
import PropTypes from 'prop-types';
import Style from './Support.module.scss';
import { RichText } from 'prismic-reactjs';
import cross from '../../images/cross.svg';
import check from '../../images/check.svg';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';

const Support = ({ primary, items }) => {
  const {
    heading,
    feature_title,
    for_free_plan,
    for_small_plan,
    for_business_plan,
    for_advance_plan,
  } = primary;

  return (
    <div className={Style.Support}>
      <Accordion
        className={Style.accordion}
        allowZeroExpanded
        allowMultipleExpanded
      >
        <AccordionItem className={Style.accordionItem}>
          <AccordionItemHeading className={Style.accordionItemHeading}>
            <AccordionItemButton className={Style.accordionItemButton}>
              <div className={Style.heading}>
                <RichText render={heading.richText} />
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className={Style.accordionItemPanel}>
            <div className={Style.content}>
              {items?.slice(0, 2)?.map((item, index) => {
                return (
                  <>
                    <div
                      className={
                        index % 2 === 0
                          ? Style.featuretitleRow
                          : Style.featureTitleRow
                      }
                      key={index}
                    >
                      <div className={Style.featuretitle}>
                        <RichText render={item?.feature_title?.richText} />
                      </div>
                      <div className={Style.lawsforplan}>
                        {item?.for_free_plan === true ? (
                          <img src={`${check}`} alt="check" />
                        ) : (
                          <img src={`${cross}`} alt="cross" />
                        )}
                      </div>
                      <div className={Style.lawsforplan}>
                        {item?.for_small_plan === true ? (
                          <img src={`${check}`} alt="check" />
                        ) : (
                          <img src={`${cross}`} alt="cross" />
                        )}
                      </div>
                      <div className={Style.lawsforplan}>
                        {item?.for_business_plan === true ? (
                          <img src={`${check}`} alt="check" />
                        ) : (
                          <img src={`${cross}`} alt="cross" />
                        )}
                      </div>
                      <div className={Style.lawsforplan}>
                        {item?.for_advance_plan === true ? (
                          <img src={`${check}`} alt="check" />
                        ) : (
                          <img src={`${cross}`} alt="cross" />
                        )}
                      </div>
                    </div>
                  </>
                );
              })}
              <div className={Style.featuretitleRow}>
                <div className={Style.featuretitle}>
                  <RichText render={feature_title.richText} />
                </div>
                <div className={Style.lawsforplan}>
                  <RichText render={for_free_plan.richText} />
                </div>
                <div className={Style.lawsforplan}>
                  <RichText render={for_small_plan.richText} />
                </div>
                <div className={Style.lawsforplan}>
                  <RichText render={for_business_plan.richText} />
                </div>
                <div className={Style.lawsforplan}>
                  <RichText render={for_advance_plan.richText} />
                </div>
              </div>
              {items?.slice(2, 4)?.map((item, index) => {
                return (
                  <>
                    <div
                      className={
                        index % 2 === 1
                          ? Style.featuretitleRow
                          : Style.featureTitleRow
                      }
                      key={index}
                    >
                      <div className={Style.featuretitle}>
                        <RichText render={item?.feature_title?.richText} />
                      </div>
                      <div className={Style.lawsforplan}>
                        {item?.for_free_plan === true ? (
                          <img src={`${check}`} alt="check" />
                        ) : (
                          <img src={`${cross}`} alt="cross" />
                        )}
                      </div>
                      <div className={Style.lawsforplan}>
                        {item?.for_small_plan === true ? (
                          <img src={`${check}`} alt="check" />
                        ) : (
                          <img src={`${cross}`} alt="cross" />
                        )}
                      </div>
                      <div className={Style.lawsforplan}>
                        {item?.for_business_plan === true ? (
                          <img src={`${check}`} alt="check" />
                        ) : (
                          <img src={`${cross}`} alt="cross" />
                        )}
                      </div>
                      <div className={Style.lawsforplan}>
                        {item?.for_advance_plan === true ? (
                          <img src={`${check}`} alt="check" />
                        ) : (
                          <img src={`${cross}`} alt="cross" />
                        )}
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

Support.propTypes = {
  primary: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
};

export default Support;
