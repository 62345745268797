import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '@components/Layout';
import PricingPage from '../scenes/PricingPage';

const NewPricingPageData = ({ data }) => {
  const pricespagenewContent = data.allPrismicNewPricingPage.edges[0];
  if (!pricespagenewContent) return null;
  const pricespagenew = pricespagenewContent.node;
  const {
    uid,
    id,
    type,
    alternate_languages,
    lang,
    data: pageData,
  } = pricespagenew;
  const activeDocMeta = { id, uid, lang, type, alternate_languages };
  const { metatitle, metadescription, canonical, body: pageContent } = pageData;

  return (
    <>
      <Layout
        activeDocMeta={activeDocMeta}
        metatitle={metatitle}
        metadescription={metadescription}
        canonical={canonical}
      >
        <PricingPage
          content={pageContent}
          metatitle={metatitle}
          canonical={canonical}
        />
      </Layout>
    </>
  );
};

NewPricingPageData.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query($lang: String) {
    allPrismicNewPricingPage(filter: { lang: { eq: $lang } }) {
      edges {
        node {
          uid
          type
          lang
          id
          alternate_languages {
            id
            lang
            type
            uid
          }
          data {
            metatitle {
              text
            }
            metadescription {
              text
            }
            canonical {
              text
            }
            body {
              ... on PrismicNewPricingPageDataBodyHeroBusiness {
                id
                slice_type
                primary {
                  subtitle {
                    richText
                  }
                  title {
                    richText
                  }
                }
              }
              ... on PrismicNewPricingPageDataBodyFeaturecomparison {
                id
                slice_type
                items {
                  planamount {
                    richText
                  }
                  plantype {
                    richText
                  }
                  planvalidity {
                    richText
                  }
                }
                primary {
                  heading {
                    richText
                  }
                }
              }
              ... on PrismicNewPricingPageDataBodyLegislationsupport {
                id
                slice_type
                primary {
                  feature_heading {
                    richText
                  }
                  feature_sub_heading {
                    richText
                  }
                  feature_title {
                    richText
                  }
                  for_advance_plan {
                    richText
                  }
                  for_business_plan {
                    richText
                  }
                  for_free_plan {
                    richText
                  }
                  for_small_plan {
                    richText
                  }
                  laws_for_plan {
                    richText
                  }
                }
                items {
                  feature_title {
                    richText
                  }
                  for_free_plan
                  for_small_plan
                  for_business_plan
                  for_advance_plan
                }
              }
              ... on PrismicNewPricingPageDataBodyBannercustomization {
                id
                slice_type
                items {
                  feature_title {
                    richText
                  }
                  for_advance_plan
                  for_business_plan
                  for_free_plan
                  for_small_plan
                }
                primary {
                  heading {
                    richText
                  }
                  feature_title {
                    richText
                  }
                  for_free_plan {
                    richText
                  }
                  for_small_plan {
                    richText
                  }
                  for_business_plan {
                    richText
                  }
                  for_advance_plan {
                    richText
                  }
                }
              }
              ... on PrismicNewPricingPageDataBodyDsarAndReporting {
                id
                slice_type
                items {
                  feature_title {
                    richText
                  }
                  for_free_plan
                  for_small_plan
                  for_business_plan
                  for_advance_plan
                }
                primary {
                  heading {
                    richText
                  }
                }
              }
              ... on PrismicNewPricingPageDataBodyPolicies {
                id
                slice_type
                items {
                  featuretitle {
                    richText
                  }
                  for_advance_plan
                  for_business_plan
                  for_free_plan
                  for_small_plan
                }
                primary {
                  heading {
                    richText
                  }
                }
              }
              ... on PrismicNewPricingPageDataBodySupportfeature {
                id
                slice_type
                items {
                  feature_title {
                    richText
                  }
                  for_advance_plan
                  for_business_plan
                  for_free_plan
                  for_small_plan
                }
                primary {
                  feature_title {
                    richText
                  }
                  for_advance_plan {
                    richText
                  }
                  for_business_plan {
                    richText
                  }
                  for_free_plan {
                    richText
                  }
                  for_small_plan {
                    richText
                  }
                  heading {
                    richText
                  }
                }
              }
              ... on PrismicNewPricingPageDataBodyPartners {
                id
                slice_type
                primary {
                  title {
                    richText
                  }
                }
                items {
                  logotype {
                    alt
                    url
                  }
                }
              }

              ... on PrismicNewPricingPageDataBodyTariffplans {
                id
                slice_type
                primary {
                  widget_currency_billing_title {
                    richText
                  }
                  monthlyperiodtogglelabel {
                    text
                  }
                  all_plans_support {
                    richText
                  }
                  anualperiodtogglelabel {
                    richText
                    text
                  }
                  currencydropdownlabel {
                    text
                  }
                  enterprisecondition {
                    text
                  }
                  enterprisebuttontext {
                    text
                  }
                  enterprisebuttonlink {
                    url
                  }
                  annualcoefficient
                  annualcondition {
                    richText
                  }
                  buttonbaselink {
                    link_type
                    url
                  }
                  buttontext {
                    richText
                  }
                  firstlawlocation {
                    text
                  }
                  firstlawtitle {
                    text
                  }
                  monthlycondition {
                    richText
                  }
                  secondlawlocation {
                    text
                  }
                  secondlawtitle {
                    text
                  }
                  thirdlawlocation {
                    text
                  }
                  thirdlawtitle {
                    text
                  }
                }
                items {
                  oneprivacypriceeur
                  oneprivacypriceusd
                  threeprivacypriceeur
                  threeprivacypriceusd
                  twoprivacypriceeur
                  twoprivacypriceusd
                  planid {
                    text
                  }
                  plantitle {
                    richText
                  }
                  plandescription {
                    richText
                  }
                  plan_benefits {
                    richText
                  }
                  bottom_clarification {
                    richText
                  }
                  check_features_on_card {
                    richText
                  }
                  buttontext {
                    richText
                  }
                  button_link {
                    url
                  }
                }
              }
              ... on PrismicNewPricingPageDataBodyQuestions {
                id
                slice_type
                primary {
                  title {
                    richText
                  }
                }
                items {
                  title {
                    richText
                  }
                  link {
                    link_type
                    url
                  }
                  content {
                    text
                    richText
                  }
                }
              }
              ... on PrismicNewPricingPageDataBodyNewTariffplan {
                id
                slice_type
                items {
                  bottom_clarification {
                    richText
                  }
                  check_features_on_card {
                    richText
                  }
                  img {
                    url
                  }
                  plan_benefits {
                    richText
                  }
                  subtitle {
                    richText
                  }
                  title {
                    richText
                  }
                }
                primary {
                  buttonlink {
                    url
                  }
                  buttontext {
                    richText
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default NewPricingPageData;
